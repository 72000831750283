import React from 'react'
import "tailwindcss/dist/base.min.css"
import { Global, css } from "@emotion/react"
import tw from 'twin.macro'
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/plugins/line-numbers/prism-line-numbers"
import "katex/dist/katex.min.css"

const Layout = ({ children }) => (
  <div>
    <Global
      styles={css`
          html {
            background-color: #3d3012;
            ${tw`text-xl`}
            ${tw`text-gray-200`}
          }
          h2,
          .h2 {
            ${tw`text-white`}
            ${tw`text-lg`}
            ${tw`underline`}
          }
          h3,
          .h3 {
            ${tw`text-green-900`}
            ${tw`text-lg`}
            ${tw`underline`}
          }
          ul {
            ${tw`ml-5`}
            ${tw`list-disc`}
          }
          ol {
            ${tw`ml-5`}
            ${tw`list-decimal`}
          }
        `}
    />
    {children}
  </div>
)

export default Layout
